import React, { useState, useEffect, useContext } from "react";
import { useRouter } from "next/compat/router";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import { BiSearch } from "react-icons/bi";
import { MENUITEMS } from "components/constant/menu";
import { AuthContext } from "helpers/auth/AuthContext";
import { isEmpty } from "helpers/utils/helpers";
import vars from "helpers/utils/vars";
import ProduceModal from "components/modals/ProduceModal";
import SearchModal from "components/modals/SearchModal";
import TopBar from "./topbar";

const NavBar = () => {
  const authContext = useContext(AuthContext);
  const onAuthModalsTriggered = authContext.onAuthModalsTriggered;
  const isAuthenticated = authContext.isAuthenticated;
  const user = authContext.user;
  const onTarget = authContext.onTarget;
  const isTabletOrMobile = useMediaQuery({
    query: vars.MEDIA_QUERIES.TABLET_MOBILE,
  });

  const [navOpen, setNavOpen] = useState(false);
  const [isProduceModal, setIsProduceModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const router = useRouter();
  const menuItems = MENUITEMS.filter((item) => {
    return isAuthenticated ? item.path !== "/about-us" : true;
  });

  useEffect(() => {
    if (window.innerWidth < 750 || window.innerWidth < 1199) {
      setNavOpen(false);
    }
  }, []);

  const openNav = () => setNavOpen(true);
  const closeNav = () => setNavOpen(false);

  const [activeNavIndex, setActiveNavIndex] = useState(0);

  useEffect(() => {
    const currentPath = router.pathname;
    let index = 0;
    if (currentPath === "/seller/dashboard" || currentPath === "/buyer/dashboard") {
      index = 4;
    } else if (currentPath === "/seller/account" || currentPath === "/buyer/account") {
      if (isAuthenticated) {
        index = 1;
      } else {
        index = 2;
      }
    } else {
      index = menuItems.findIndex((item) => item.path === currentPath);
    }
    setActiveNavIndex(index !== -1 ? index : 0);
  }, [router.pathname]);

  const openMblNav = (menu) => {
    if (menu.title === "MEMBERSHIP PLANS") {
      if (isAuthenticated) {
        router.push({
          pathname: `/${user.role === "seller" ? "seller" : "buyer"}/account`,
          query: { active: "plan" },
        });
      } else {
        onTarget({ pathname: "/account", query: { active: "plan" } });
        onAuthModalsTriggered("user_type");
      }
    } else if (menu.title === "PRODUCE") {
      setIsProduceModal(true);
    } else if (menu.title === "REPORTS") {
      if (!isAuthenticated) {
        onTarget(menu.path);
        onAuthModalsTriggered("login");
      } else if (
        isEmpty(user.subs) ||
        user.subs?.membership_type?.toLowerCase() === "blue"
      ) {
        onAuthModalsTriggered("Permission", "", {
          backButton: true,
          message: "Oops. You need to upgrade your subscription to view these!",
        });
      } else {
        router.push(menu.path);
      }
    } else {
      router.push(menu.path);
    }
  };

  return (
    <div className="main-navbar">
      <div id="mainnav">
        {!isAuthenticated && (
          <div
            className={`toggle-nav ${isAuthenticated ? "auth" : "non-auth"}`}
            onClick={openNav}
          >
            <i className="fa fa-bars sidebar-bar"></i>
          </div>
        )}

        <ul className="nav-menu" style={{ right: navOpen ? "0px" : "-410px" }}>
          <li className="back-btn" onClick={closeNav}>
            <div className="mobile-back text-right">
              <span>Back navbar</span>
              <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
            </div>
          </li>
          {isTabletOrMobile && (
            <li>
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                  border: "1px solid gray",
                  borderRadius: 25,
                  maxWidth: "85%",
                  cursor: "pointer",
                }}
                className="nav-link mt-3 mb-4 ms-3 px-3 py-2 d-flex align-items-center"
                onClick={() => setSearchModal(true)}
              >
                <BiSearch className="me-3" />
                <span>Search</span>
              </div>
            </li>
          )}
          {menuItems.map((menuItem, i) => (
            <li key={i}>
              {menuItem.type === "link" ? (
                <Link
                  href={menuItem.path}
                  className={`nav-item nav-link ${
                    i === activeNavIndex ? "active" : ""
                  }`}
                  onClick={() => {
                    setActiveNavIndex(i);
                  }}
                >
                  {menuItem.title}
                </Link>
              ) : (
                <span
                  className={`nav-item nav-link ${
                    i === activeNavIndex ? "active" : ""
                  }`}
                  onClick={() => {
                    openMblNav(menuItem);
                    setActiveNavIndex(i);
                  }}
                >
                  {menuItem.title}
                </span>
              )}
            </li>
          ))}
          {isAuthenticated && (
            <li>
              <span
                className={`nav-item nav-link ${
                  activeNavIndex === menuItems.length ? "active" : ""
                }`}
                onClick={() => {
                  router.push({
                    pathname: `/${
                      user.role === "seller" ? "seller" : "buyer"
                    }/dashboard`,
                    query: { active: "plan" },
                  });
                  setActiveNavIndex(menuItems.length);
                }}
              >
                {user.role === "seller" ? "SELLER" : "BUYER"} DASHBOARD
              </span>
            </li>
          )}
          {isTabletOrMobile && (
            <li>
              <TopBar topClass="text-left my-4 ps-3" />
            </li>
          )}
        </ul>
      </div>

      {isProduceModal && (
        <ProduceModal
          isShow={isProduceModal}
          onToggle={(val) => setIsProduceModal(val)}
        />
      )}
      <SearchModal
        isShow={searchModal}
        onToggle={(val) => setSearchModal(val)}
      />
    </div>
  );
};

export default NavBar;
