import { create } from "zustand";

const useMainStore = create((set) => ({
  categories: [],
  premiumAdverts: [],
  planAdverts: [],
  reports: [],
  banner: [],
  setData: (newData) =>
    set((state) => ({
      ...state, 
      ...newData, 
    })),
}));

export default useMainStore;