import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import Select from "react-select";
import { Spinner, Label, Button, Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { Row, Col } from "antd-grid-layout";
import "antd-grid-layout/styles/antd-grid-layout.css";
import useSearchDataStore from "store/searchDataStore";

const SearchHeader = ({
  onSearch,
  searchFieldCustomStyles = {},
  asModal = false,
}) => {
  const {
    produces,
    countriesData,
    loading,
    setProduces,
    setCountriesData,
    setLoading,
  } = useSearchDataStore();
  const [searchText, setSearchText] = useState("");
  const [country, setCountry] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [type, setType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.post("/api/search-data");
        setProduces(response.data.produces);
        setCountriesData(response.data.countries);
      } catch (err) {
        alert(err.toString());
      } finally {
        setLoading(false);
      }
    };

    if (!produces.length || !countriesData.length) {
      fetchData();
    }
  }, [produces, countriesData, setProduces, setCountriesData, setLoading]);

  const handleSearch = (e) => {
    e.preventDefault();
    let id = "";

    if (type) {
      id = type;
    } else if (subCategory) {
      id = subCategory;
    } else if (category) {
      id = category;
    }

    if (searchText || id || country)
      onSearch({ searchText, id, country: country?.value });
  };

  const onCategoryChanged = (val) => {
    if (!val) {
      setCategory("");
      setSubCategories([]);
      setType("");
      setTypes([]);
      return;
    }

    setCategory(val);
    const subCategories = produces.filter(
      (produce) => produce.refers_toISbb_agrix_produce_typesID === val.value
    );

    setSubCategories(subCategories);
  };

  const categories = produces.filter(
    (produce) => produce.refers_toISbb_agrix_produce_typesID === null
  );
  const categoryOptions = categories
    .map((category) => ({ value: category.numeric_id, label: category.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const subCategoryOptions = subCategories
    .map((subCategory) => ({
      label: subCategory.name,
      value: subCategory.numeric_id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const typeOptions = types
    .map((type) => ({
      label: type.name,
      value: type.numeric_id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const countries = countriesData.filter(
    (country) => country.refers_toISbb_agrix_countriesID === null
  );
  const countryOptions = countries
    .map((country) => ({
      label: country.name,
      value: country.numeric_id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const onSubCategoryChanged = (val) => {
    if (!val) {
      setSubCategory(null);
      setTypes([]);
      setType("");
      return;
    }

    setSubCategory(val);
    const types = produces.filter(
      (produce) => produce.refers_toISbb_agrix_produce_typesID === val.value
    );
    setTypes(types);
  };

  const onCountryChanged = (val) => {
    if (!val) {
      setCountry(null);
      return;
    }
    setCountry(val);
  };
  // console.log(category, subCategory, type, country)
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 60,
        }}
      >
        <Spinner color="primary" size={asModal ? "" : "sm"}>
          {""}
        </Spinner>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="search-form">
        <Row
          justify="space-between"
          align="middle"
          gutter={[20, asModal ? 10 : 20]}
        >
          <Col span={24} md={5}>
            <Label>Search produce</Label>
            <Input
              type="search"
              className="w-100 search-input"
              placeholder="Search produce"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={searchFieldCustomStyles}
            />
          </Col>
          <Col span={24} md={4}>
            <Label>Produce Category</Label>
            <Select
              className={`w-100`}
              placeholder="Select categories"
              options={categoryOptions}
              name="category"
              value={category}
              onChange={onCategoryChanged}
              isClearable={true}
            />
          </Col>
          <Col span={24} md={5}>
            <Label>Produce Sub Category</Label>
            <Select
              className={`w-100`}
              options={subCategoryOptions}
              placeholder="Select sub categories"
              name="sub_category"
              isDisabled={!category}
              value={subCategory}
              onChange={onSubCategoryChanged}
              isClearable={true}
            />
          </Col>
          <Col span={24} md={4}>
            <Label>Type</Label>
            <Select
              className={`w-100`}
              options={typeOptions}
              placeholder="Select types"
              name="type"
              value={type}
              isDisabled={!subCategory}
              onChange={(val) => setType(val)}
              isClearable={true}
            />
          </Col>
          <Col span={24} md={4}>
            <Label>Country</Label>
            <Select
              className={`w-100`}
              options={countryOptions}
              placeholder="Select country"
              name="country"
              value={country}
              onChange={onCountryChanged}
              isClearable={true}
            />
          </Col>
          <Col style={asModal ? { width: "100%" } : {}}>
            <Button
              color="success"
              block={asModal}
              className={`${
                asModal
                  ? "d-flex align-items-center justify-content-center mt-3"
                  : "mt-4"
              }`}
              onClick={handleSearch}
            >
              <FaSearch size={20} />
              {asModal ? <span>&nbsp; Search</span> : null}
            </Button>
          </Col>
        </Row>
        {/* <div className='d-sm-none mobile-search'>
						<Search />
					</div> */}
      </div>
    </Fragment>
  );
};

export default SearchHeader;
