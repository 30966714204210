import React, { useContext } from 'react'
import { AuthContext } from '../../../helpers/auth/AuthContext'

const TopBar = ({ topClass = 'mb-1 me-2 text-right' }) => {

	const authContext = useContext(AuthContext)
	const onAuthModalsTriggered = authContext.onAuthModalsTriggered
	const user = authContext.user

	if (user?._id) return null

	return (
        // <div id="topHeader" className={`top-header ${topClass? topClass: ''}`}>
        // </div>
        (<div className={topClass}>
            <button
				className='btn btn-solid btn-default-plan btn-post btn-sm me-3'
				onClick={() => onAuthModalsTriggered('user_type')}
			>
				<i className='fa fa-user' aria-hidden='true'></i> Register
			</button>
            <button className='btn btn-solid btn-default-plan btn-post btn-sm' onClick={() => onAuthModalsTriggered('login')}>
				<i className='fa fa-sign-in' aria-hidden='true'></i> Login
			</button>
        </div>)
    );
}

export default TopBar
