import { create } from "zustand";

const useSearchDataStore = create((set) => ({
  produces: [],
  countriesData: [],
  loading: false,
  error: null,

  setProduces: (produces) => set({ produces }),
  setCountriesData: (countriesData) => set({ countriesData }),
  setLoading: (loading) => set({ isLoading: loading }),
  setError: (error) => set({ error }),
}));

export default useSearchDataStore;
